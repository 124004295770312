// import React, { useState } from "react";
// import { ROUTES } from "../routes/RouterConfig";
// import { useNavigate } from "react-router-dom/dist";
// import welcome from "../assets/images/Welcome.jpg";
// import img1 from "../assets/images/Welcome.jpg";
// function Welcome() {
//   const navigate = useNavigate();
//   return (
//     <div className="bg-[#ffffff]">
//       <section className="py-10 sm:py-16 lg:py-24">
//         <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
//           <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
//             <div>
//               <h1 className="text-3xl font-bold text-black sm:text-4xl lg:text-5xl">
//                 Welcome to Sofyrus
//                 <div className="relative inline-flex">
//                   <span className="absolute inset-x-0 bottom-0 border-b-[30px] border-[#4ADE80]"></span>
//                   <h1 className="relative text-3xl font-bold text-black sm:text-4xl lg:text-5xl">
//                     Teams platform.
//                   </h1>
//                 </div>
//               </h1>
//               <div className="mt-4">
//                 <span
//                   className="mt-5 text-lg"
//                   //  style={{ whiteSpace: "nowrap" }}
//                 >
//                   Unlocking a World of Possibilities with Our Subscription
//                 </span>
//               </div>
//               <div className="mt-10 sm:flex sm:items-center sm:space-x-8">
//                 <button
//                   onClick={() => navigate(ROUTES.Requirement)}
//                   className="inline-flex items-center justify-center px-10 py-4 text-base font-semibold text-white transition-all duration-200 bg-green-500 hover:bg-green-600 focus:bg-green-600"
//                 >
//                   {" "}
//                   GO TO DASHBOARD{" "}
//                 </button>
//               </div>
//             </div>

//             <div>
//               <img
//                 className="w-full"
//                 // src="https://img.freepik.com/premium-vector/illustration-vector-graphic-cartoon-character-welcome_516790-756.jpg?w=740"
//                 src="https://img.freepik.com/free-vector/isometric-cms-concept_23-2148807389.jpg?t=st=1714390272~exp=1714393872~hmac=836020c5bc50696b593a16581ec03f9cbdac923f6adff77b35fdbfa91afa9260&w=740"
//                 // src={welcome}
//                 alt=""
//               />
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default Welcome;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../Config/config";

const Welcome = () => {
  const location = useLocation();
  const [selectedValue, setSelectedValue] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  // console.log("locationState", location.state.selectedBuildIndex.name);
  const navigate = useNavigate();
  const [fullName, setFullName] = useState({
    firstName: "",
    lastName: "",
  });
  const [userDetails, setUserDetails] = useState({
    userName: "",
    companyName: "",
    userEmail: "",
    message: "",
  });

  const changeDir = (dir) => {
    navigate(dir);
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.selectedBuildIndex &&
      location.state.selectedBuildIndex.name
    ) {
      setSelectedValue(location.state.selectedBuildIndex?.name);
    }
  }, [location?.state?.selectedBuildIndex?.name]);

  const HandleChange = (key, value) => {
    setUserDetails({ ...userDetails, [key]: value });
  };

  const fullNameHandler = (key, value) => {
    setFullName({
      ...fullName,
      [key]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowMessage(true);
    const uDetails = { ...userDetails };
    uDetails["userName"] = `${fullName?.firstName} ${fullName?.lastName}`;
    uDetails["message"] = selectedValue
      ? `${selectedValue}`
      : userDetails.message;
    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}/user/sendDemo`,
        uDetails
      );
      let { status, data } = response;
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  return (
    <div className="Login ">
      <main className="profile-page">
        <section className="bg-white">
          <div className="grid grid-cols-1 lg:grid-cols-2 h-[100vh]">
            <div className="relative flex items-start px-4 pb-10 lg:pt-4 pt-16 sm:pb-16 md:justify-center lg:pb-24 bg-gradient-to-b from-gray-900 to-gray-700 sm:px-6 lg:px-8">

              <div className="relative flex flex-col gap-16">
              <img src="https://img.freepik.com/free-vector/abstract-creative-website-hosting-illustration_23-2149223770.jpg?w=740&t=st=1716366875~exp=1716367475~hmac=d918acf9fe3d9500be1844efcb782277ac22c92ed4427e44f137eac842f894d2" alt="" />

                <div className="w-full max-w-xl xl:w-full xl:mx-auto xl:pr-16 pr-8 xl:max-w-2xl">
                  {/* <h3 className="lg:text-4xl text-2xl font-bold text-white mb-2">
                    GOT A PROJECT IN MIND? LET’S DISCUSS IT
                  </h3> */}
                  {/* <br></br> */}
                  {/* <div className="flex"> */}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                  >
                    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 24.984375 6.9863281 A 1.0001 1.0001 0 0 0 24 8 L 24 22.173828 A 3 3 0 0 0 22 25 A 3 3 0 0 0 22.294922 26.291016 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 23.708984 27.705078 A 3 3 0 0 0 25 28 A 3 3 0 0 0 28 25 A 3 3 0 0 0 26 22.175781 L 26 8 A 1.0001 1.0001 0 0 0 24.984375 6.9863281 z"></path>
                  </svg> */}
                  {/* <p className="text-xl font-normal text-white">
                    <span className="text-xl font-bold text-white">
                      {" "}
                      3-months{" "}
                    </span>
                    - MVP time-to-market
                  </p>
                  <br></br> */}
                  {/* </div> */}

                  {/* <p className="text-xl font-normal text-white">
                    <span className="text-xl font-bold text-white">
                      {" "}
                      Power of iterations{" "}
                    </span>
                    - we prefer to work in 2-week sprints
                  </p>
                  <br></br>
                  <p className="text-xl font-normal text-white">
                    <span className="text-xl font-bold text-white">
                      {" "}
                      Risk-free start{" "}
                    </span>
                    - if you aren’t satisfied with the first 2 weeks of work,
                    your fee will be $0
                  </p>
                  <br></br>
                  <p className="text-xl font-normal text-white">
                    <span className="text-xl font-bold text-white">
                      {" "}
                      Complete teams & on-demand developers{" "}
                    </span>
                    - both options are available
                  </p>
                  <br></br> */}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center px-4 py-7 bg-white sm:px-6 lg:px-8 sm:py-2 lg:py-24">
              {showMessage ? (
                <div className="space-y-5 ">
                  <h3 className=" text-5xl lg:text-5xl w-[100%]  md:text-xl mx-w-[60%] font-bold text-black mb-3">
                    Thank you for connecting with us.We will come back to you
                    within the next 24 hours
                  </h3>
                </div>
              ) : (
                <div className="xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto">
                  <form action="#" method="POST">
                    <div className="space-y-5">
                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          First name
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                          <input
                            type="text"
                            name=" userName"
                            value={fullName.firstName}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              fullNameHandler("firstName", Event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          Last name
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                          <input
                            type="text"
                            name="userName"
                            value={fullName.lastName}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              fullNameHandler("lastName", Event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          Company name
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                          <input
                            type="text"
                            name="companyName"
                            value={userDetails.companyName}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              HandleChange("companyName", Event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          Work Email
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                          <input
                            type="email"
                            name=" userEmail"
                            value={userDetails.userEmail}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              HandleChange("userEmail", Event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for=""
                          className="text-sm font-medium text-gray-900 red"
                        >
                          Set your Budget
                        </label>
                        <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                          <input
                            type="text"
                            name="message"
                            value={userDetails.message}
                            id=""
                            className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            onChange={(Event) =>
                              HandleChange("message", Event.target.value)
                            }
                          />
                        </div>
                      </div>

                      {/* <div>
                      <label
                        for=""
                        className="text-sm font-medium text-gray-900 red"
                      >
                        How did you hear about us?
                      </label>
                      <div className="mt-2.5 relative text-gray-400 focus-within:text-gray-600">
                        <input
                          type="text"
                          name=""
                          id=""
                          className="block w-full py-2 pl-2 pr-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md  focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                        />
                      </div>
                    </div> */}

                      <div>
                        <button
                          type="submit"
                          className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
                          onClick={(Event) => handleSubmit(Event)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Welcome;


import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import dayjs from "dayjs";
import visitorServices from "../../Services/Requirement/visitorService";
import { ToastContainer, toast } from "react-toastify";
import Config from "../../Config/config";

const AllVisitors = () => {
  const [visitors, setVisitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchField, setSearchField] = useState("fullName"); // New state for search field
  const [filteredVisitors, setFilteredVisitors] = useState([]);
  const [pageSize, setPageSize] = useState(10); // State for page size

  // Function to fetch all visitors
  const fetchAllVisitors = async () => {
    setLoading(true);
    let allData = [];
    let currentPage = 1;
    while (true) {
      try {
        const response = await fetch(
          `${Config.apiBaseUrl}/auth/visitors?page=${currentPage}&limit=1000`
        );
        const data = await response.json();
        if (data.data.length === 0) break; // Exit loop if no more data
        allData = allData.concat(data.data);
        currentPage += 1;
      } catch (error) {
        console.log("err", error);
        break;
      }
    }
    setVisitors(allData);
    setFilteredVisitors(allData);
    setTotalPages(Math.ceil(allData.length / pageSize));
    setLoading(false);
  };

  // Function to fetch visitors for the current page
  const fetchVisitors = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${Config.apiBaseUrl}/auth/visitors?page=${page}&limit=${pageSize}`
      );
      const data = await response.json();
      setLoading(false);
      console.log("data", data);
      setVisitors(data.data); // Access the 'data' array from the response
      setFilteredVisitors(data.data); // Initially, filteredVisitors is the same as visitors
      setTotalPages(Math.ceil(data.total / pageSize));
    } catch (error) {
      console.log("err", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVisitors(); // Fetch visitors when the component mounts or page/pageSize changes
  }, [page, pageSize]); // Dependency array includes pageSize

  const deleteVisitor = async (dRecord) => {
    const response = await visitorServices.deleteVistor(dRecord);
    const { status, data } = response;
    if (status === 200 && data) {
      toast.success("Visitor deleted successfully!!", {
        position: "bottom-right",
        autoClose: 2000,
      });
      fetchVisitors(); // Refresh the visitor list after deletion
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value); // Update the page number
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update the search query state
  };

  // New function to handle search field change
  const handleSearchFieldChange = (event) => {
    setSearchField(event.target.value); // Update the search field state
  };

  const handleSearchClick = () => {
    const filtered = visitors.filter(visitor =>
      visitor[searchField]?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredVisitors(filtered); // Update the filteredVisitors state with the search results
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize); // Update the pageSize state
    setPage(1); // Reset to first page when page size changes
  };

  const loadAllPages = () => {
    fetchAllVisitors(); // Fetch all visitors across all pages
  };

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <div className="flex justify-end p-4">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              className="border border-gray-300 rounded px-4 py-2"
            />
            <select
              onChange={handleSearchFieldChange} // Handle search field change
              className="ml-2 px-4 py-2 border border-gray-300 rounded"
              value={searchField}
            >
              <option value="fullName">Name</option>
              <option value="email">Email</option>
              <option value="companyName">Company Name</option>
            </select>
            <button
              onClick={handleSearchClick}
              className="ml-2 px-4 py-2 bg-blue-500 text-white rounded"
            >
              Search
            </button>
            <select
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              className="ml-4 px-4 py-2 border border-gray-300 rounded"
              value={pageSize}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={1000}>1000</option>
            </select>
            <button
              onClick={loadAllPages}
              className="ml-2 px-4 py-2 bg-green-500 text-white rounded"
            >
              Load All Pages
            </button>
          </div>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Sr. NO
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Company Name
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Visited Date
                </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-gray-50">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {Array.isArray(filteredVisitors) &&
                filteredVisitors.map((visitor, i) => (
                  <tr key={visitor.id}>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {(page - 1) * pageSize + i + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {visitor?.fullName}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {visitor?.email}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {visitor?.companyName}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                      {dayjs(visitor?.created_at).format("YYYY-MM-DD")}
                    </td>
                    <td>
                      <div
                        className="bg-red-600 text-xs px-2 py-1 rounded-md text-white hover:bg-red-700 cursor-pointer w-14"
                        onClick={() => deleteVisitor(visitor)}
                      >
                        Delete
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex justify-center items-center my-4 my-pag">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default AllVisitors;



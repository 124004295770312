import React, { useState } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mode1 from "../assets/images/mode1.png";
import mode2 from "../assets/images/mode2.png";
import mode3 from "../assets/images/mode3.png";
import { ROUTES } from "../routes/RouterConfig";
import { useNavigate } from "react-router-dom";
import Config from "../Config/config";

function Pricing() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalText, setmodalText] = useState("");
  const [image, setImage] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userDetails, setUserDetails] = useState({
    userEmail: "",
    companyName: "",
    userName: "",
    message: "Consulation",
  });

  const handleChange = (key, value) => {
    setUserDetails((preState) => ({
      ...preState,
      [key]: value,
    }));
  };

  const changeChandler = (clicValue) => {
    setUserDetails((preState) => ({ ...preState, message: clicValue }));
    if (clicValue == "Consultation") {
      setmodalText(`With our consultation Package we will help you
      navigate through complex project planning phase. You
      can expect a clear roadmap along with a team trial to
      assess our capabilities`);
      setImage(mode1);
    }
    if (clicValue == "FullStack") {
      setmodalText(`  Empower Your Project with Our Full-Stack Team: Three
                          Dedicated Members Ready to Tackle Frontend or Backend
                          Tasks! Rest Assured, Our Developers are Seasoned
                          Experts, Well-Versed in Industry Best Practices and
                          Domain Knowledge.`);
      setImage(mode2);
    }
    if (clicValue == "CustomPlan") {
      setmodalText(`Our custom plan is tailored to help you build a
      high-performing team, comprising Fullstack experts,
      cloud solution architects, project managers, QA
      specialists, and more.
      Connect with Us Now!`);
      setImage(mode3);
    }
    handleOpen();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}/user/sendDemo`,
        userDetails
      );
      console.log("response", response);
      const { data, status } = response;
      if (status === 201 && data) {
        toast.success(
          "Thank you for connecting with us!  We'll reach out to you soon.",
          {
            position: "bottom-right",
            autoClose: 2000,
          }
        );
        setUserDetails({
          userEmail: "",
          companyName: "",
          userName: "",
          message: "",
        });
      }
      setOpen(false);
    } catch (error) {
      console.log("err", error);
    }
  };
  return (
    <section className="py-10 bg-gray-50 sm:py-16 lg:py-24" id="Pricing">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-2xl mx-auto text-center">
          <h2 className="text-xl font-bold leading-tight text-black sm:text-2xl lg:text-4xl">
            Get Your Team Now
          </h2>
          <p className="max-w-lg mx-auto mt-4 text-base leading-relaxed text-gray-600">
            Explore our pricing plans tailored to your needs, offering
            flexibility, value, and exceptional service.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row">
          {/* first div */}
          <div className="grid max-w-sm grid-cols-1 gap-6 mx-auto mt-8 sm:mt-16 sm:grid-cols-1">
            <div className="bg-white border-4 border-green-500 rounded-md">
              <div className="p-6 md:py-10 md:px-9">
                <div className="inline-block px-4 py-2 bg-blue-100 rounded-full">
                  <h3 className="text-sm font-semibold text-blue-600">
                    One-Time
                  </h3>
                </div>
                <p className="mt-2 text-2xl font-bold text-black">
                  Project Discovery
                </p>
                <p className="mt-2 text-3xl font-bold text-black line-through">
                  $6000
                </p>
                <p className="mt-2 text-5xl font-bold text-black">$1000</p>
                {/* <p className="mt-2 text-base text-gray-600">Per month</p> */}

                <ul className="flex flex-col mt-8 space-y-4">
                  {/* <li className="inline-flex items-center space-x-2">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base font-medium text-gray-900">
                      {" "}
                      Consultation{" "}
                    </span>
                  </li> */}

                  <li className="inline-flex items-center space-x-2">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base font-medium text-gray-900">
                      {" "}
                      Requirement Analysis{" "}
                    </span>
                  </li>

                  <li className="inline-flex items-center space-x-2">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base font-medium text-gray-900">
                      {" "}
                      Technology Recommendation{" "}
                    </span>
                  </li>
                  <li className="inline-flex items-center space-x-2">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base font-medium text-gray-900">
                      {" "}
                      Milestones Definition
                    </span>
                  </li>
                  <li className="inline-flex items-center space-x-2">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base font-medium text-gray-900">
                      {" "}
                      Budget Planning
                    </span>
                  </li>

                  <li className="inline-flex items-center space-x-2">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base font-medium text-gray-900">
                      {" "}
                      2- Week Trial of Our Dev Services{" "}
                    </span>
                  </li>
                </ul>

                <a
                  // href="#"
                  // onClick={() => changeChandler("Consultation")}
                  onClick={() => navigate(ROUTES.Hiring)}
                  title=""
                  className="inline-flex items-center justify-center w-full px-4 py-3 mt-14 font-semibold text-white transition-all duration-200 rounded-md bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
                  role="button"
                >
                  {" "}
                 Get in Touch{" "}
                </a>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className="modal-ui lg:w-[75%] w-full flex flex-col  justify-center">
                    <div className="flex flex-wrap justify-between  gap-3 items-center">
                      <div className="lg:w-[58%] 2xl:w-[58%] xl:w-[58%] w-full">
                        <h1 className=" text-2xl font-bold text-black my-3">
                          Make the Right Choice
                        </h1>
                        <h4 className="text-base font-medium">{modalText}</h4>
                        <div className="flex w-full gap-3 my-4">
                          <TextField
                            className="w-1/2 "
                            id="outlined-basic"
                            label="Your Name"
                            variant="outlined"
                            color="success"
                            value={userDetails.userName}
                            onChange={(e) =>
                              handleChange("userName", e.target.value)
                            }
                          />
                          <TextField
                            className="w-1/2 "
                            id="outlined-basic"
                            label="Your Email"
                            variant="outlined"
                            color="success"
                            value={userDetails.userEmail}
                            onChange={(e) =>
                              handleChange("userEmail", e.target.value)
                            }
                          />
                        </div>
                        <TextField
                          className="w-full mb-2"
                          id="outlined-basic"
                          label="Your Company Name"
                          variant="outlined"
                          color="success"
                          value={userDetails.companyName}
                          onChange={(e) =>
                            handleChange("companyName", e.target.value)
                          }
                        />
                        <textarea
                          class="form-control my-2 "
                          id="message-text"
                          placeholder="Message"
                          value={userDetails.message}
                          onChange={(e) =>
                            handleChange("message", e.target.value)
                          }
                        ></textarea>
                        <button
                          type="submit"
                          onClick={(e) => {
                            handleSubmit(e);
                          }}
                          className="flex items-center uppercase gap-2 px-5 py-3 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                        >
                          Submit
                        </button>
                      </div>
                      <div className="lg:w-[38%] 2xl:w-[38%] xl:w-[38%] w-full">
                        <div className="lg:flex hidden justify-center items-end mr-0 relative">
                          <img
                            className="object-contain"
                            style={{
                              height: 450,
                              width: 400,
                            }}
                            src={image}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
          {/* second div */}
          <div className="grid max-w-sm grid-cols-1 gap-6 mx-auto mt-8 sm:mt-16 sm:grid-cols-1">
            <div className="bg-white border-4 border-green-500 rounded-md">
              <div className="p-6 md:py-10 md:px-9">
                <div className="inline-block px-4 py-2 bg-blue-100 rounded-full">
                  <h3 className="text-sm font-semibold text-blue-600">
                    One-Time
                  </h3>
                </div>
                <p className="mt-2 text-2xl font-bold text-black">
                  MVP Team (3 Months*)
                </p>
                <p className="mt-2 text-3xl font-bold text-black line-through">
                  $20,000
                </p>
                <p className="mt-2 text-5xl font-bold text-black">$9000</p>

                <div className="flex flex-col justify-between">
                  <ul className="flex flex-col mt-8 space-y-4">
                    {/* <li className="inline-flex items-center space-x-2">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base font-medium text-gray-900">
                      {" "}
                      Full Stack{" "}
                    </span>
                  </li> */}

                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        Fix Project deadlines, Scope & Budget{" "}
                      </span>
                    </li>

                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        UI/ UX Design{" "}
                      </span>
                    </li>
                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        MVP deployed to production
                      </span>
                    </li>
                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        Help with core metric tracing & product usage analytics
                      </span>
                    </li>

                    {/* <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        Free Access to Robonito No-Code QA Platform{" "}
                      </span>
                    </li> */}
                  </ul>

                  <a
                    // href="#"
                    onClick={() => navigate(ROUTES.Hiring)}
                    // onClick={() => changeChandler("FullStack")}
                    title=""
                    className="inline-flex items-center justify-center w-full px-4 py-3 mt-8 font-semibold text-white transition-all duration-200 rounded-md bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
                    role="button"
                  >
                    {" "}
                   Get in Touch{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* third div */}
          <div className="grid max-w-sm grid-cols-1 gap-6 mx-auto mt-8 sm:mt-16 sm:grid-cols-1">
            <div className="bg-white border-4 border-green-500 rounded-md">
              <div className="p-6 md:py-10 md:px-9">
                <div className="inline-block px-4 py-2 bg-blue-100 rounded-full">
                  <h3 className="text-sm font-semibold text-blue-600">
                    Monthly
                  </h3>
                </div>

                <p className="mt-2 text-2xl font-bold text-black">
                  Custom Plan
                </p>
                <p className="mt-2 text-4xl font-bold text-black">
                  Get a Quote
                </p>

                <div className="flex flex-col justify-between">
                  <ul className="flex flex-col mt-8 space-y-4">
                    {/* <li className="inline-flex items-center space-x-2">
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-base font-medium text-gray-900">
                      {" "}
                      Custom Org Plan{" "}
                    </span>
                  </li> */}

                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        Define Your Team{" "}
                      </span>
                    </li>

                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        Domain Experts{" "}
                      </span>
                    </li>
                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        Project Management System
                      </span>
                    </li>
                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        Flexible Timing & Support
                      </span>
                    </li>

                    <li className="inline-flex items-center space-x-2">
                      <svg
                        className="flex-shrink-0 w-5 h-5 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium text-gray-900">
                        {" "}
                        Free Access to Robonito No-Code QA Platform{" "}
                      </span>
                    </li>
                  </ul>

                  <a
                    // href="#"
                    // onClick={() => changeChandler("CustomPlan")}
                    onClick={() => navigate(ROUTES.Hiring)}
                    title=""
                    className="inline-flex items-center justify-center w-full px-4 py-3 mt-[87px] font-semibold text-white transition-all duration-200 rounded-md bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
                    role="button"
                  >
                    {" "}
                   Get in Touch{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-5xl  text-left mt-10">
          <h2 className="text-sm font-medium leading-tight text-black sm:text-sm lg:text-sm mb-3">
            * We provide a free trial: your fee will be $0 if you{" "}
            <b>aren't satisfied</b> with the first 2 weeks of work<br></br>
            ** Based on our experience, the price range for most MVP's is{" "}
            <b>$10000 - $40000</b>. For complex or vertical SaaS, the price may
            differ.
          </h2>
          {/* <a
            href="#Footer"
            title=""
            className="inline-flex items-center justify-center px-10 py-3 text-base font-semibold text-white transition-all duration-200 bg-green-500 focus:outline-none hover:opacity-80 focus:opacity-80"
            role="button"
            // onClick={scroll}
          >
            {" "}
           Get in Touch{" "}
          </a> */}
        </div>
      </div>
    </section>
  );
}

export default Pricing;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Container } from "react-bootstrap";
import { ROUTES } from "../../routes/RouterConfig";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Config from "../../Config/config";
const TeamsCms = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [userDetails, setUserDetails] = useState({
    content: "",
    meta: "",
    slug: "",
    subHeading: "",
    heading: "",
  });

  const handleChange = (key, value) => {
    setUserDetails({
      ...userDetails,
      [key]: value,
    });
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
    // console.log("file", profile);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = new FormData();
      payload.append("image", image);
      payload.append("heading", userDetails.heading);
      payload.append("subHeading", userDetails.subHeading);
      payload.append("slug", userDetails.slug);
      payload.append("meta", userDetails.meta);
      payload.append("content", userDetails.content);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data", // Specify the content type for FormData
          // Add any other headers as needed
          // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN', // Example of an Authorization header
        },
      };
      const response = await axios.post(
        `${Config.apiBaseUrl}/cms`,
        payload,
        config
      );
      console.log("response", response);
      const { data, status } = response;
      if (status === 201 && data) {
        toast.success(
          "Thank you for connecting with us!  We'll reach out to you soon.",
          {
            position: "bottom-right",
            autoClose: 2000,
          }
        );
        setUserDetails({
          content: "",
          meta: "",
          slug: "",
          subHeading: "",
          heading: "",
        });
      }
      // setOpen(false);
    } catch (error) {
      console.log("err", error);
    }
  };

  const changeDir = (dir) => {
    navigate(dir);
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const currentContent = editorState.getCurrentContent();
    const text = currentContent.getPlainText("\u0001");
    setUserDetails((preState) => ({ ...preState, content: text }));
    console.log("Text value:", text);
  };

  return (
    <div className="Login">
      {/* <Header /> */}

      <div className="py-16">
        <div className="lg:w-8/12 md:w-8/12 sm:w-8/12 w-full m-auto ">
          <h2 className="font-semibold text-4xl  text-center pb-8">
            {/* User Details */}
          </h2>
          <Form>
            <Container fluid="sm">
              <Row>
                <Col sm="12">
                  <Row className="my-3">
                    <Col sm="6">
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Upload image
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Heading
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter here"
                          value={userDetails.heading}
                          onChange={(e) =>
                            handleChange("heading", e.target.value)
                          }
                          // {...getFieldProps("useremail")}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          sub heading
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter here"
                          value={userDetails.subHeading}
                          onChange={(e) =>
                            handleChange("subHeading", e.target.value)
                          }
                          // {...getFieldProps("useremail")}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">Slug</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="slug"
                          placeholder="Enter here "
                          value={userDetails.slug}
                          onChange={(e) => handleChange("slug", e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">meta</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter here"
                          value={userDetails.meta}
                          onChange={(e) => handleChange("meta", e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Content
                        </Form.Label>
                        {/* <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter here"
                        /> */}
                        {/* <Editor
                          editorState={editorState}
                          wrapperClassName="demo-wrapper border"
                          editorClassName="demo-editor h-48"
                          value={userDetails.content}
                          onChange={(e) =>
                            handleChange("content", e.target.value)
                          }
                          onEditorStateChange={onEditorStateChange}
                        /> */}
                        <Editor
                          editorState={editorState}
                          wrapperClassName="demo-wrapper border"
                          editorClassName="demo-editor h-48"
                          onEditorStateChange={onEditorStateChange}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col sm="6">
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Mobile Number
                        </Form.Label>

                        <Form.Control
                          className="rounded-md h-10 mr-3"
                          type="number"
                          placeholder="Enter Your Number"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Last Name"
                        />
                      </Form.Group>

                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">DOB</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="date"
                          placeholder="Enter Your Email ID"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Skills
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Skill"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Hobbies
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Hobbies"
                        />
                      </Form.Group>
                    </Col> */}
                  </Row>

                  {/* <Row className="my-3">
                    <Col sm="12">
                      <h2 className="my-3 font-semibold text-xl">
                        Work Experience
                      </h2>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Company Name
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Company Name"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">From</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="date"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">
                          Responsiblities
                        </Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Responsiblities"
                        />
                      </Form.Group>
                    </Col>
                    <Col sm="6">
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">Role</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="text"
                          placeholder="Enter Your Role"
                        />
                      </Form.Group>
                      <Form.Group className="mb-2">
                        <Form.Label className="font-semibold">To</Form.Label>
                        <Form.Control
                          className="rounded-md h-10 mb-4"
                          type="date"
                        />
                      </Form.Group>
                    </Col>
                  </Row> */}

                  <div className="text-center">
                    <Button
                      className="bg-[#0d6efd] rounded-full w-32 mt-12"
                      variant="primary"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Form>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default TeamsCms;

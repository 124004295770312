import React, { useEffect, useLayoutEffect, useState } from "react";
import team from "../../assets/images/teamImg.jpg";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Banner from "../../components/Banner";
import Pricing from "../../components/Pricing";
import axios from "axios";
import { useLocation } from "react-router-dom";
import blogServices from "../../Services/Requirement/blogServices";
import { IoAddOutline } from "react-icons/io5";
import Modal from "@mui/material/Modal";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
} from "draft-js";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import draftToHtml from 'draftjs-to-html';
import Config from "../../Config/config";
// import {
//   EditorState,
//   convertToRaw,
//   ContentState,
//   convertFromHTML,
//   convertFromRaw,
// } from "draft-js";

function My_Article() {
  const { pathname } = useLocation();
  const [articles, setArticles] = useState([]);
  const [blog, setBlog] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [userId ,setUserId] = useState('')
  let currentUserId = '1234'

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  // const onEditorStateChange = (editorState) => {
  //   setEditorState(editorState);
  //   const currentContent = editorState.getCurrentContent();
  //   const text = currentContent.getPlainText("\u0001");
  //   setBlog((preState) => ({ ...preState, content: text }));
  //   console.log("Text value:", text);
  // };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const currentContent = editorState.getCurrentContent();
    // Convert content to raw JS object to preserve formatting
    const rawContentState = JSON.stringify(convertToRaw(currentContent));
    setBlog((prevState) => ({ ...prevState, content: rawContentState }));
    console.log("Content state:", rawContentState);
  };

  const contentToHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  const blogChangeHandler = (key, value) => {
    setBlog((preSate) => ({
      ...preSate,
      [key]: value,
    }));
  };

  const editBlog = async (BlogData) => {
    let { heading, subHeading, content, meta, slug } = BlogData;
    let payload = {
      heading,
      subHeading,
      meta,
      slug,
      content,
    };
    debugger;
    const response = await axios.put(
      `${Config.apiBaseUrl}/cms/update/${BlogData?.id}`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    debugger;
    const { data, status } = response;
    if (status === 200) {
      toast.success("updated successfully.", {
        position: "top-left",
        autoClose: 2000,
      });
      // setBlog({
      //   heading: "",
      //   subHeading: "",
      // });
      handleClose();
      fetchDataBySlug();
    }
  };

  const allArticles = async () => {
    try {
      const response = await axios.get(
        `${Config.apiBaseUrl}/cms/all`
      );
      const { data, status } = response;
      if (data && status === 200) {
        setArticles(data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchDataBySlug = () => {
    try {
      const slug = pathname?.split("/")[1];
      let decodedSlug = decodeURIComponent(slug);
      fetch(`${Config.apiBaseUrl}/cms/${decodedSlug}`)
        .then((response) => response.json())
        .then((data) => setBlog(data));
    } catch (error) {
      console.log("err", error);
    }
  };
  useEffect(() => {fetchDataBySlug()
    let tokenData = JSON.parse(localStorage.getItem("authDetails"));
    setUserId(tokenData?.userId);
  },[]);

  // useEffect(() => {
  //   if (blog?.content) {
  //     const contentState = ContentState.createFromText(blog.content);
  //     setEditorState(EditorState.createWithContent(contentState));
  //   }
  // }, [blog?.content]);
  useEffect(() => {
    if (blog?.content) {
      try {
        const contentState = convertFromRaw(JSON.parse(blog.content));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (e) {
        // If content is not in JSON format, fallback to plain text
        const contentState = ContentState.createFromText(blog.content);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [blog?.content]);
  return (
    <div className="bg-white" id="Testimonials">
      <Header />
      {/*  {Array.isArray(articles) &&
        articles?.slice(4, 5)?.map((article) => (
          <> */}
      <section className="py-10 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex justify-end pb-4">
          { blog && blog.id === userId && (
            <button
            type="submit"
            onClick={handleOpen}
            className="flex items-center  uppercase gap-2  px-3 py-2 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
          >
            <IoAddOutline size={"1.5rem"} />
            Edit
          </button>
          )}  
            
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="modal-ui flex flex-col  justify-center">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Edit Blog
                </Typography>
                <div className="flex flex-col mt-3 gap-3 items-center">
                  <TextField
                    className="w-full"
                    size="small"
                    id="outlined-basic"
                    label="Heading"
                    variant="outlined"
                    color="success"
                    value={blog?.heading}
                    onChange={(e) =>
                      blogChangeHandler("heading", e.target.value)
                    }
                  />
                  <TextField
                    className="w-full"
                    id="outlined-basic"
                    size="small"
                    label="SubHeading"
                    variant="outlined"
                    color="success"
                    value={blog?.subHeading}
                    onChange={(e) =>
                      blogChangeHandler("subHeading", e.target.value)
                    }
                  />
                  <div className="xl:w-full xl:max-w-lg 2xl:max-w-xl xl:mx-auto">
                    <div className="space-y-5">
                      <div>
                        <label
                          htmlhtmlFor=""
                          className="text-base font-medium text-gray-900 mb-2"
                        >
                          {" "}
                          Content
                        </label>
                        <Editor
                          editorState={editorState}
                          wrapperClassName="demo-wrapper border"
                          editorClassName="demo-editor h-40"
                          onEditorStateChange={onEditorStateChange}
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "list",
                              "textAlign",
                              "colorPicker",
                              "link",
                              "emoji",
                              "history",
                            ],
                            inline: {
                              inDropdown: false,
                              options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                              ],
                            },
                          }}
                        />
                      </div>

                      <div className="flex justify-between gap-3">
                        <button
                          type="submit"
                          className="inline-flex h-10 uppercase items-center justify-center w-full px-4 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                          onClick={() => editBlog(blog)}
                          // onClick={() =>
                          //   Object.keys(task)?.length && task?.id
                          //     ? editTask(task)
                          //     : createTask()
                          // }
                        >
                          {/* {Object.keys(task)?.length && task?.id
                                ? "Update"
                                : "Submit"} */}
                          Update
                        </button>
                        <button
                          type="submit"
                          className="inline-flex h-10 uppercase items-center justify-center w-full px-4 py-3 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-md bg-slate-400 focus:outline-none hover:opacity-80 focus:opacity-80"
                          // onClick={resetHandler}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
              <h1 className="text-4xl font-bold text-black sm:text-4xl lg:text-6xl">
                {blog?.heading}
              </h1>
            </div>
            <div>
              <img
                className="w-full"
                src={blog?.image}
                // src={TeamsSofyrus}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="w-11/12 bg-white  rounded-sm md:w-9/12 lg:w-10/12 sm:w-10/12 align-center m-auto md:px-16  lg:px-16 px-4 py-4">
        <div className="max-w-5xl mx-auto ">
          {/* <p>{article?.content}</p> */}
          <h2 className="font-bold lg:text-2xl md:text-2xl sm:text-xl text-xl text-black py-2 opacity-100">
            {blog?.subHeading}
          </h2>

          <p
            dangerouslySetInnerHTML={{
              __html: contentToHtml,
            }}
          ></p>
        </div>
      </section>
      {/*  </>
        ))} */}

      <Pricing />
      <Footer />
    </div>
  );
}

export default My_Article;

import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import PaymentService from "../Payment/PaymentService";
import { ROUTES } from "../../routes/RouterConfig";
// const {us} from ''
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Config from "../../Config/config";
import { useNavigate } from "react-router-dom/dist";
const CheckoutForm = () => {
  const [paymentIntent, setPaymentIntent] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const GetPaymentIntent = async () => {
    const response = await fetch(
      `${Config.apiBaseUrl}/user/payment-intent`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: 655,
          currency: "inr",
          userId: "cb430c10-eba8-4981-af49-fcfb4c0d8e2f",
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to fetch payment intent");
    }

    const data = await response.json();
    setPaymentIntent(data);
    console.log("data", data);
    ;
  };

  // const { client_secret: clientSecret } = paymentIntent;

  // const postIntent = async () => {
  //   ;
  //   try {
  //     let payload = {
  //       amount: 655,
  //       currency: "inr",
  //       userId: "cb430c10-eba8-4981-af49-fcfb4c0d8e2f",
  //     };
  //     const response = await PaymentService.getPaymentIntentSubscription(
  //       payload
  //     );
  //     if (response.status === 201) {
  //       setPaymentIntent(response.data);
  //       ;
  //     }
  //   } catch (error) {
  //     ;
  //     console.log("err", error);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    // const res = await fetch(
    //   `https://teamsapi.sofyrus.com/api/user/payment-intent`,
    //   {
    //     method: "POST",
    //   },
    //   {
    //     amount: 100,
    //     currency: "INR",
    //     userId: "cb430c10-eba8-4981-af49-fcfb4c0d8e2f",
    //   }
    // );

    // const response = await fetch(
    //   `https://teamsapi.sofyrus.com/api/user/payment-intent`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({
    //       amount: 100,
    //       currency: "INR",
    //       userId: "cb430c10-eba8-4981-af49-fcfb4c0d8e2f",
    //     }),
    //   }
    // );
    // ;
    // if (!response.ok) {
    //   throw new Error("Failed to fetch payment intent");
    // }

    // const data = await response.json();
    // setPaymentIntent(data);
    // ;
    // console.log("data", data);

    const { client_secret: clientSecret } = paymentIntent;
    ;
    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/welcome`,
        // "https://github.com/SofyrusTechnologies/team-project/pull/20",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    ;
  };

  useEffect(() => {
    GetPaymentIntent();
    // postIntent();
  }, []);

  return (
    <div>
      <div className="text-center mt-4">
        <div>
          <p className="text-black font-sans font-medium text-xl">
            {/* Payment for Subscription */}Subscription Plans
          </p>
          <hr className="border-t my-1 mx-auto w-1/6" />
        </div>
      </div>

      <div className="lg:w-6/12 w-full shadow-lg rounded-xl px-16 py-5 mt-8 mx-auto">
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <button
            type="submit"
            disabled={!stripe || !elements}
            className="flex items-center justify-center  uppercase gap-2 w-[20%]  px-3 py-2 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80 my-3"
          >
            Pay
          </button>
          {/* <button
                  type="submit"
                  onClick={handleRequirementOpen}
                  className="flex items-center  uppercase gap-2  px-3 py-2 text-sm font-medium text-white transition-all duration-200 border border-transparent rounded-lg bg-[#4ade80] focus:outline-none hover:opacity-80 focus:opacity-80"
                >
                  <IoAddOutline size={"1.5rem"} />
                  Add Requirement
                </button> */}
          {/* Show error message to your customers */}
          {errorMessage && <div>{errorMessage}</div>}
        </form>
      </div>
    </div>
  );
};

export default CheckoutForm;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ROUTES } from "../routes/RouterConfig";
import { useNavigate } from "react-router-dom";
import Config from "../Config/config";

const Footer = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    userEmail: "",
    companyName: "",
    userName: "",
  });
  let year = new Date().getFullYear();
  useEffect(() => {
    getQueryParams(window.location);
  }, []);

  const getQueryParams = async function (url) {
    const queryParams = {};
    const urlParams = new URLSearchParams(url.search.replaceAll("&amp;", "&"));
    for (const [key, value] of urlParams) {
      queryParams[key] = value;
    }
    const hasVisited =
      window.localStorage.getItem("visited") == null
        ? false
        : window.localStorage.getItem("visited") == 1
        ? true
        : false;
    if (Object.keys(queryParams).length != 0 && !hasVisited) {
      //put api call here 
      axios.post(`${Config.apiBaseUrl}/auth/addVisitors`, {
        fullName: queryParams.fname + " " + queryParams.lname,
        companyName: queryParams.company,
        email: queryParams.email,
        message: "none",
      });
      if (queryParams.utm_source) {
        axios.post(`${Config.apiBaseUrl}/auth/addVisitors`, {
          fullName: "Google Ads",
          companyName: queryParams.utm_source,
          email: "a@a.com",
          message: "none",
        });
      }
      window.localStorage.setItem("visited", "1");
    }
  };
  // var params = getQueryParams(window.location);
  // console.log("res", response);

  const handleChange = (key, value) => {
    setUserDetails((preState) => ({
      ...preState,
      [key]: value,
    }));
  };

  //api connect connect user with us.
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${Config.apiBaseUrl}/user/sendEmail`,
        userDetails
      );
      console.log("response", response);
      const { data, status } = response;
      if (status === 201 && data) {
        toast.success(
          "Thank you for connecting with us!  We'll reach out to you soon.",
          {
            position: "bottom-right",
            autoClose: 2000,
          }
        );
        setUserDetails({
          userEmail: "",
          companyName: "",
          userName: "",
        });
      }
    } catch (error) {
      console.log("err", error);
    }
  };
  return (
    <section className="py-10 bg-gray-100 sm:pt-16 lg:pt-24" id="Footer">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="grid grid-cols-2 md:col-span-3 lg:grid-cols-6 gap-y-16 gap-x-12">
          <div className="col-span-2 md:col-span-3 lg:col-span-2 lg:pr-8">
            <img
              className="w-auto h-9"
              src={require("../assets/images/sofyrus_b39d664183.webp")}
              alt=""
            />

            <p className="text-base leading-relaxed text-gray-600 mt-7">
              Software team substitution for startups, scaleups and enterprises.
            </p>

            <p className="text-base leading-relaxed text-gray-600 mt-7">
              <span className="font-semibold">Address:</span> AlSulymaniah -
              Riyadh
              <br />
              <span className="font-semibold">Email:</span> contact@sofyrus.com
            </p>

            <ul className="flex items-center space-x-3 mt-9">
              <li>
                <a
                  href="https://in.linkedin.com/company/sofyrus-technologies"
                  target="blank"
                  title=""
                  className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                >
                  <svg
                    // xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    // width="100"
                    // height="100"
                    viewBox="0 0 50 50"
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.facebook.com/sofyrustech/"
                  target="blank"
                  title=""
                  className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                >
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://www.instagram.com/sofyrustechnologies/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
                  target="blank"
                  title=""
                  className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                >
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                    <circle cx="16.806" cy="7.207" r="1.078"></circle>
                    <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                  </svg>
                </a>
              </li>

              <li>
                <a
                  href="https://twitter.com/sofyrustech"
                  target="blank"
                  title=""
                  className="flex items-center justify-center text-white transition-all duration-200 bg-gray-800 rounded-full w-7 h-7 hover:bg-blue-600 focus:bg-blue-600"
                >
                  <svg
                    className="w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>

          <div>
            <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">
              Company
            </p>

            <ul className="mt-6 space-y-4">
              <li>
                <a
                  href="#"
                  title=""
                  className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  About{" "}
                </a>
              </li>

              <li>
                <a
                  href="#"
                  title=""
                  className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  Features{" "}
                </a>
              </li>

              {/* <li>
                <a
                  href="#"
                  title=""
                  className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  HealthCare
                </a>
              </li> */}
            </ul>
          </div>

          <div>
            <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">
              Solutions
            </p>

            <ul className="mt-6 space-y-4">
              <li>
                <a
                  href="#"
                  onClick={() => navigate(ROUTES.HealthTech)}
                  title=""
                  className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  HealthCare{" "}
                </a>
              </li>
              {/* <li>
                <a
                  href="#"
                  title=""
                  className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  Terms & Conditions{" "}
                </a>
              </li> */}

              {/* <li>
                <a
                  href="#"
                  title=""
                  className="flex text-base text-black transition-all duration-200 hover:text-blue-600 focus:text-blue-600"
                >
                  {" "}
                  Privacy Policy{" "}
                </a>
              </li> */}
            </ul>
          </div>

          <div className="col-span-2 md:col-span-1 lg:col-span-2 lg:pl-8">
            <p className="text-sm font-semibold tracking-widest text-gray-400 uppercase">
              Contact Us
            </p>

            <form action="#" method="POST" className="mt-6">
              <div className="mb-2">
                <label htmlFor="name" className="sr-only">
                  Full Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={userDetails.userName}
                  onChange={(e) => handleChange("userName", e.target.value)}
                  id="name"
                  placeholder="Your Name"
                  className="block w-full px-4 py-2.5 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                />
              </div>
              <div className="mb-2">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  value={userDetails.userEmail}
                  onChange={(e) => handleChange("userEmail", e.target.value)}
                  name="email"
                  id="email"
                  placeholder="Your Email"
                  className="block w-full px-4 py-2.5 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                />
              </div>

              <div className="mb-2">
                <label htmlFor="company" className="sr-only">
                  Email
                </label>
                <input
                  type="text"
                  value={userDetails.companyName}
                  onChange={(e) => handleChange("companyName", e.target.value)}
                  name="company"
                  id="company"
                  placeholder="Your Company name"
                  className="block w-full px-4 py-2.5 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 rounded-md focus:outline-none focus:border-blue-600 caret-blue-600"
                />
              </div>

              <button
                type="submit"
                onClick={(e) => {
                  handleSubmit(e);
                }}
                disabled={
                  !userDetails.userName.trim() ||
                  !userDetails.userEmail.trim() ||
                  !userDetails.companyName.trim()
                }
                className="inline-flex items-center justify-center px-6 py-2.5 mt-3 font-semibold text-white transition-all duration-200 bg-[#4ade80] rounded-md hover:bg-[#39a762] focus:bg-[#3b945c] cursor-pointer"
              >
                Connect
              </button>
            </form>
          </div>
        </div>

        <hr className="mt-16 mb-10 border-gray-500" />

        <p className="text-sm text-center text-gray-600">
          © Copyright {year}, All Rights Reserved by Sofyrus
        </p>
      </div>
    </section>
  );
};

export default Footer;
